import { registry, container } from "tsyringe";
import { makeAutoObservable } from "mobx";
import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import OrderDomain from "./OrderDomain";
import LoadOrderListCommandParameter from './LoadOrderListCommandParameter';
import Commands from './Commands';
import OrderRepository from "./OrderRepository";
import Constants from './Constants';
import DeviceRepository from '../routes/device/DeviceRepository';

@registry([{ token: OrderListingViewModel, useClass: OrderListingViewModel }])
export default class OrderListingViewModel {
    private get loadOrderCommand(): cod.ICommand<LoadOrderListCommandParameter> {
        return container.resolve<cod.ICommander>(
            nameof<cod.Services>((t) => t.ICommander)
        ).get(Commands.loadOrderList);
    }
    private get orderRepository(): OrderRepository {
        return container.resolve<OrderRepository>(OrderRepository);
    }
    private get deviceRepository(): DeviceRepository {
        return container.resolve<DeviceRepository>(DeviceRepository);
    }
    private get authenticator(): cod.IAuthenticator {
        return container.resolve<cod.IAuthenticator>(
            nameof<cod.Services>((t) => t.IAuthenticator)
        );
    }

    networkFailed: boolean = false;
    ready: boolean = false;
    orders: OrderDomain[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async load(): Promise<void> {
        if (!this.authenticator.claims) {
            window.location.href = '/signin';
            return;
        }

        const sid = this.authenticator.claims[Constants.sidkey];
        if (!sid) {
            window.location.href = '/signin';
            return;
        }

        await this.deviceRepository.load({});
        const r = await this.loadOrderCommand.execute(new LoadOrderListCommandParameter(true));

        if (r.result && r.result.success) {
            this.orders = this.orderRepository.data
                .where((d) => d.partitionKey == sid)
                .toArray();
            for (var i = 0; i < this.orders.length; i++) {
                var order = this.orders[i];
                await order.loadDevice();
            }
            this.ready = true;
        }
        else {
            // TODO
        }
    };
}