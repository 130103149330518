import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import { container } from "tsyringe";
import Commands from './Commands';
import BusyGroups from './BusyGroups';
import LoadOrderListCommandParameter from './LoadOrderListCommandParameter';
import OrderRepository from "./OrderRepository";
import Constants from './Constants';

export default class LoadOrderListCommand extends cod.GenericCommand<LoadOrderListCommandParameter, void> {

    private get orderRepository(): OrderRepository {
        return container.resolve<OrderRepository>(OrderRepository);
    }
    private get authenticator(): cod.IAuthenticator {
        return container.resolve<cod.IAuthenticator>(
            nameof<cod.Services>((t) => t.IAuthenticator)
        );
    }

    public get id(): string {
        return Commands.loadOrderList;
    }

    protected async executeCore(parameter: LoadOrderListCommandParameter): Promise<cod.OperationResult> {
        this.commander.setBusy(BusyGroups.loadOrderList, undefined);
        try {
            if (!this.authenticator.claims || !this.authenticator.claims[Constants.sidkey]) {
                window.location.href = '/signin';
                return new cod.OperationResult({ code: cod.InternalError.authenticationRequired });
            }

            const sid = this.authenticator.claims[Constants.sidkey];
            const r = await this.orderRepository.load({
                partitionKeyStart: sid,
                partitionKeyEnd: sid,
                force: parameter.force
            });

            return cod.OperationResult.parse(r);

        } finally {
            this.commander.setBusy(BusyGroups.loadOrderList, undefined);
        }
    }
}