import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import { container } from "tsyringe";
import Commands from './Commands';
import BusyGroups from './BusyGroups';
import OrderRepository from "./OrderRepository";
import LoadOrderCommandParameter from './LoadOrderCommandParameter';
import Constants from './Constants';

export default class LoadOrderCommand extends cod.GenericCommand<LoadOrderCommandParameter, void> {
    private get orderRepository(): OrderRepository {
        return container.resolve<OrderRepository>(OrderRepository);
    }
    private get authenticator(): cod.IAuthenticator {
        return container.resolve<cod.IAuthenticator>(
            nameof<cod.Services>((t) => t.IAuthenticator)
        );
    }

    public get id(): string {
        return Commands.loadOrder;
    }

    protected async executeCore(parameter: LoadOrderCommandParameter): Promise<cod.OperationResult> {
        this.commander.setBusy(BusyGroups.loadOrder, parameter.rowKey);
        try {
            if (!this.authenticator.claims || !this.authenticator.claims[Constants.sidkey]) {
                window.location.href = '/signin';
                return new cod.OperationResult({ code: cod.InternalError.authenticationRequired });
            }

            const r = await this.orderRepository.load({
                partitionKeyStart: parameter.partitionKey,
                partitionKeyEnd: parameter.partitionKey,
                rowKeyStart: parameter.rowKey,
                rowKeyEnd: parameter.rowKey,
                force: true
            });

            return cod.OperationResult.parse(r);

        } finally {
            this.commander.setBusy(BusyGroups.loadOrder, parameter.rowKey);
        }
    }
}