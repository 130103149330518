import { registry, container } from "tsyringe";
import { makeAutoObservable } from "mobx";
import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import PaymentMethod from "./PaymentMethod";
import RentCommandParameter from "./RentCommandParameter";
import Commands from "./Commands";
import LoadOrderCommandParameter from "./LoadOrderCommandParameter";
import OrderRepository from "./OrderRepository";
import Constants from "./Constants";
import Order from "./Order";
import { siteLanguage } from '../locales';

@registry([{ token: HomeViewModel, useClass: HomeViewModel }])
export default class HomeViewModel {
    private get loadOrderCommand(): cod.ICommand<LoadOrderCommandParameter> {
        return container
            .resolve<cod.ICommander>(nameof<cod.Services>((t) => t.ICommander))
            .get(Commands.loadOrder);
    }
    private get orderRepository(): OrderRepository {
        return container.resolve<OrderRepository>(OrderRepository);
    }
    private get authenticator(): cod.IAuthenticator {
        return container.resolve<cod.IAuthenticator>(
            nameof<cod.Services>((t) => t.IAuthenticator)
        );
    }
    private get rentCommand(): cod.ICommand<RentCommandParameter> {
        return container
            .resolve<cod.ICommander>(nameof<cod.Services>((t) => t.ICommander))
            .get(Commands.rent);
    }

    errorMessage: string | undefined | null;
    busy = false;

    promoCodeWanted: boolean = false;
    promoCode: string = "";

    promoCodeAvailable: boolean | undefined;

    get isAuthenticated(): boolean {
        return this.authenticator.isAuthenticated;
    }

    get paymentMethods(): PaymentMethod[] {
        if (!this.authenticator.isAuthenticated) {
            return [];
        }

        if (!this.authenticator.claims) {
            return [];
        }

        const claim = this.authenticator.claims["paymentMethods"];
        if (!claim) {
            return [];
        }

        return JSON.parse(claim) as PaymentMethod[];
    }

    constructor() {
        makeAutoObservable(this);
        const promo = new URLSearchParams(window.location.search).get("promo");
        if (promo) {
            this.promoCode = promo;
            this.onOK();
        }
    }

    onInputPromoCode = () => {
        this.promoCodeWanted = true;
    }

    onOK = () => {
        if (this.promoCode.trim().toUpperCase() === "PLUGO") {
            this.promoCodeAvailable = true;
            this.promoCodeWanted = false;
        }
        else {
            this.promoCodeAvailable = false;
        }
    }

    onCancel = () => {
        this.promoCodeAvailable = undefined;
        this.promoCodeWanted = false;
        this.promoCode = "";
    }

    onLoad = (): void => {
        if (this.authenticator.isAuthenticated && this.paymentMethods.length == 0) {
            this.authenticator.cleanup();
        }
    }

    onRent = async (): Promise<void> => {
        this.errorMessage = undefined;
        const search = new URLSearchParams(window.location.search);
        const device = search.get("device");
        let promo = search.get("promo");
        if (!promo && this.promoCodeAvailable) {
            promo = this.promoCode;
        }

        let signupUrl = "/signup";
        if (device) {
            signupUrl += '?device=' + device;
            if (promo) {
                signupUrl += '&promo=' + promo;
            }
        }
        if (
            !this.isAuthenticated ||
            !this.authenticator.claims ||
            !this.authenticator.claims[Constants.sidkey]
        ) {
            window.location.href = signupUrl;
            return;
        }

        if (!device) {
            // TODO 需要做一个设备选择页面
            this.errorMessage = siteLanguage == 'en' ? "Please scan QR code or visit the FULL URL located on device label."
                : "请扫描二维码或者访问二维码下方的[完整网址]进行租借.";
            return;
        }

        this.busy = true;
        try {
            const r = await this.rentCommand.execute(
                new RentCommandParameter({
                    device: device,
                    paymentMethod: this.paymentMethods[0].id,
                    promoCode: this.promoCode,
                })
            );

            if (r.result && r.result.success) {
                const sid = this.authenticator.claims[Constants.sidkey];
                const rk = (r.result.result as Order).RowKey;
                const exist = await this.checkOrderExist(sid, rk, 0);

                if (exist) {
                    window.location.href = "/rental?order=" + rk + "&device=" + device + "&promo=" + promo;
                } else {
                    this.errorMessage = siteLanguage == 'en' ? "Please pick up your powerbank. If no powerbank has been given by the device, please contact our support team at support@plugo.co.nz"
                        : "请取走充电宝. 如果设备上没有充电宝弹出, 请联系我们的客服团队 support@plugo.co.nz";
                }
            } else if (r.result && r.result.code == 403) {
                this.errorMessage = siteLanguage == 'en' ? 'Your account has been disabled. Please contact our customer support team for further details.'
                    : '您的账户已被停止使用，如有疑问，请联系我们的客服团队';
            } else if (r.result && r.result.code == 7003) {
                window.location.href = signupUrl;
            } else {
                if (r.result && r.result.message) {
                    this.errorMessage = r.result.message;
                }
            }
        } finally {
            this.busy = false;
        }
    };

    onSelect = (e: HTMLSelectElement) => {
        /* eslint-disable */
        (this as any)[e.name] = e.value.trim();
    };

    private async checkOrderExist(pk: string, rk: string, retry: number): Promise<boolean> {
        /* eslint-disable */
        if (retry > 20) {
            return false;
        }

        const r = await this.loadOrderCommand.execute(new LoadOrderCommandParameter({ partitionKey: pk, rowKey: rk }));

        if (r.result && r.result.success) {
            const exist = this.orderRepository.data.singleOrDefault((d) => d.partitionKey == pk && d.rowKey == rk);
            if (exist) {
                return true;
            }
        }

        return new Promise<boolean>(resolve =>
            setTimeout(async () => {
                const x = await this.checkOrderExist(pk, rk, ++retry);
                resolve(x);
            }, 3000)
        );
    }

    onInput = (e: HTMLInputElement) => {
        (this as any)[e.name] = e.value.trim();
    };
}