const locales = [
    {
        language: 'zh',
        messages: {
            loading: "加载中...",
            helpLink: "帮助",
            privacyLink: "隐私",
            termsLink: "条款",
            home: {
                greetings: '欢迎使用 Plugo!',
                myOrders: '我的订单',
                time: "时长",
                cost: "费用",
                hour: "小时",
                hours: "小时",
                day: "天",
                startRent: "开始租用",
                payBy: "付款方式:",
                promoApplied: "优惠码已应用, 第1个小时免费",
                invalidPromo: "优惠码无效",
                hasPromo: "使用优惠码?",
                pricingDetailsLink: "价格细目",
                pricingDetails: "第1个小时 $1.99, 后续每小时 $0.99. 每24个小时封顶费用 $6.98, 每次租用封顶费用 $19.98. 不足1个小时的时间按照1个小时计算. 所有金额均为新西兰元 (NZD).",
                error: {
                    paymentMethodMissing: "需要指定支付方式",
                }
            },
            signup: {
                accountInfo: "账户信息",
                hasAccount: "已注册过账户?",
                signin: "登入",
                checkingAvailability: "正在检查是否可用...",
                subscribe: "接收优惠活动或更新",
                paymentInfo: "付款信息",
                secured: "所有付款交易信息均会被安全的加密传输",
                cardsAccepted: "支持中国银联卡，维萨卡(Visa)，万事达卡(Mastercard)，美国运通卡（American Express），日本国际（JCB），发现卡（Discover）",
                savePaymentInfo: "我们将安全的保存以上信息以提供更好的支付体验",
                signupLink: "注册并开始租用",
                proceedWarning: "继续注册即意味着您理解并接受我们的",
                TnC: "用户条款",
                privacy: "隐私条款",
                and: "和",
                refund: "退款政策",
                error: {
                    accountExist: "此电子邮箱已被注册使用, 请尝试使用其他邮箱",
                }
            },
            signin: {
                greetings: "欢迎回来!",
                signinPrompt: "请登入您的账户以继续",
                signup: "注册",
                signin: "登入",
                noAccount: "没有账户?",
                forgetPassword: "忘记密码?",
                error: {
                    incorrectLogin: "您的账号或密码错误",
                    noInternet: "您的网络连接不稳定, 请重试",
                }
            },
            rental: {
                status: "状态",
                currentCost: "当前花费",
                startTime: "开始时间",
                endRent: "结束租借",
                noRental: "您当前并未租借任何设备",
                endRentInstruction: "请将充电线放回原位, 然后将充电宝插回任意 Plugo 网点的设备中",
                loading: "我们正在确认您的租借结果, 可能需要一些时间, 请耐心等待... 我们将收取您 $19.98 作为押金, 并且会在您归还电池后退还此押金.",
            },
            orders: {
                order: "订单",
                orders: "下单",
                pickup: "借出",
                return: "归还",
                noOrder: "您没有任何租借订单"
            }
        }
    }
];

let siteLanguage = 'en';
if (navigator.language.startsWith("zh")) {
    siteLanguage = 'zh';
}

export { locales, siteLanguage };