import { registry, container } from "tsyringe";
import { makeAutoObservable } from "mobx";
import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import OrderDomain from "./OrderDomain";
import LoadOrderListCommandParameter from './LoadOrderListCommandParameter';
import LoadOrderCommandParameter from './LoadOrderCommandParameter';
import Commands from './Commands';
import OrderRepository from "./OrderRepository";
import Constants from './Constants';
import { siteLanguage } from '../locales';

@registry([{ token: RentalViewModel, useClass: RentalViewModel }])
export default class RentalViewModel {
    private get loadOrderListCommand(): cod.ICommand<LoadOrderListCommandParameter> {
        return container.resolve<cod.ICommander>(
            nameof<cod.Services>((t) => t.ICommander)
        ).get(Commands.loadOrderList);
    }
    private get loadOrderCommand(): cod.ICommand<LoadOrderCommandParameter> {
        return container.resolve<cod.ICommander>(
            nameof<cod.Services>((t) => t.ICommander)
        ).get(Commands.loadOrder);
    }
    private get orderRepository(): OrderRepository {
        return container.resolve<OrderRepository>(OrderRepository);
    }
    private get authenticator(): cod.IAuthenticator {
        return container.resolve<cod.IAuthenticator>(
            nameof<cod.Services>((t) => t.IAuthenticator)
        );
    }

    sourceLocationName: string | undefined;
    get sourceDevice(): string {
        if (this.rental) {
            return this.rental.entity.Source.split("$$$")[0];
        }

        return "";
    }

    rentalDurationSeconds: number = 0;
    networkFailed: boolean = false;
    ready: boolean = false;
    get startTime(): Date | undefined {
        if (this.rental) {
            return new Date(this.rental.entity.Created);
        }

        return undefined;
    }
    rental: OrderDomain | undefined;
    endRentalClick: boolean = false;
    sourceLocationGoogleMapLink: string | undefined;

    errorMessage: string | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    update(): void {
        const started = this.startTime;
        if (started) {
            this.rentalDurationSeconds = (new Date().getTime() - started.getTime()) / 1000;
            return;
        }

        this.rentalDurationSeconds = 0;
    }

    static elapsed(seconds: number): string {
        const str = new Date(seconds * 1000).toISOString().substr(11, 8);
        const days = Math.floor(seconds / 86400);
        if (days > 0) {
            return `${days}.${str}`;
        }

        return str;
    }

    async load(orderID: string | null | undefined): Promise<void> {
        if (!this.authenticator.claims) {
            window.location.href = '/signin';
            return;
        }

        const sid = this.authenticator.claims[Constants.sidkey];
        if (!sid) {
            window.location.href = '/signin';
            return;
        }

        let currentOrder = undefined;

        if (orderID && orderID != null) {
            let lastOrder = undefined;
            const current = await this.checkOrder(sid, orderID, lastOrder, 0, 60);
            if (current) {
                currentOrder = current;
            }
        }
        else {
            let lastOrder = undefined;
            const current = await this.checkOrderList(sid, lastOrder, 0, 60);
            if (current) {
                currentOrder = current;
            }
        }

        this.ready = true;
        if (currentOrder) {
            if (currentOrder.entity.Status == 2) {
                this.rental = currentOrder
                await this.rental.loadDevice();
                this.sourceLocationName = await this.rental.sourceLocationName;
                this.sourceLocationGoogleMapLink = await this.rental.sourceLocationGoogleMapLink;
            }
            else {
                if (currentOrder.entity.Status == 0 || currentOrder.entity.Status == 1) {
                    this.errorMessage = siteLanguage == 'en' ? "Oops! Something wrong with your order. Please try again." : "系统处理您的订单时发生错误, 请稍后重试.";
                }
                else if (currentOrder.entity.Status == 3) {
                    this.errorMessage = siteLanguage == 'en' ? "You don't have any active rental at the moment." : '您当前没有任何有效租借.';
                }
                else if (currentOrder.entity.Status == 4) {
                    this.errorMessage = siteLanguage == 'en' ? "Failed to dispense powerbank. Please try renting again." : "格口弹出电池失败, 请尝试重新租借";
                }
                else {
                    this.rental = currentOrder
                    await this.rental.loadDevice();
                    this.sourceLocationName = await this.rental.sourceLocationName;
                    this.sourceLocationGoogleMapLink = await this.rental.sourceLocationGoogleMapLink;
                }
            }
        }
        else {
            this.errorMessage = siteLanguage == 'en' ? "You don't have any active rental at the moment." : '您当前没有任何有效租借.';
        }
    };

    private async checkOrderList(pk: string, lastOrder: OrderDomain | undefined, retry: number, maxRetry: number): Promise<OrderDomain | undefined> {
        if (retry > maxRetry) {
            return lastOrder;
        }
        const r = await this.loadOrderListCommand.execute(new LoadOrderListCommandParameter(true));

        if (r.result && r.result.success) {
            const result = this.orderRepository.data
                .where((d) => d.partitionKey == pk)
                .firstOrDefault();
            if (result) {
                lastOrder = result;
                if (result.entity.Status == 2) {
                    return result;
                }
            }
        }
        return new Promise<OrderDomain | undefined>(resolve =>
            setTimeout(async () => {
                const x = await this.checkOrderList(pk, lastOrder, ++retry, maxRetry);
                resolve(x);
            }, 1000)
        );
    }

    private async checkOrder(pk: string, rk: string, lastOrder: OrderDomain | undefined, retry: number, maxRetry: number): Promise<OrderDomain | undefined> {
        if (retry > maxRetry) {
            return lastOrder;
        }
        const r = await this.loadOrderCommand.execute(new LoadOrderCommandParameter({ partitionKey: pk, rowKey: rk }));

        if (r.result && r.result.success) {
            const result = this.orderRepository.data
                .where((d) => d.partitionKey == pk && d.rowKey == rk)
                .singleOrDefault();
            if (result) {
                lastOrder = result;
                if (result.entity.Status != 0 && result.entity.Status != 1) {
                    return result;
                }
            }
        }
        return new Promise<OrderDomain | undefined>(resolve =>
            setTimeout(async () => {
                const x = await this.checkOrder(pk, rk, lastOrder, ++retry, maxRetry);
                resolve(x);
            }, 1000)
        );
    }

    onEndRent = (e: any): void => {
        this.endRentalClick = true;
    }
}