import * as cod from "@cod/cod-web";
import { registry, instanceCachingFactory } from "tsyringe";
import { nameof } from "ts-simple-nameof";

@registry([
    {
        token: nameof<cod.Services>((t) => t.IConfigurationProvider),
        useFactory: instanceCachingFactory<RuntimeConfigurationProvider>((c) =>
            c.resolve(RuntimeConfigurationProvider)
        ),
    },
])
export class RuntimeConfigurationProvider
    implements cod.IConfigurationProvider {
    get(key: string): string {
        switch (key) {
            case cod.Settings.API_URI:
                return "https://api.plugo.co.nz";
            case cod.Settings.TABLE_URI:
                return "https://plugodatabase.table.core.windows.net";
            default:
                throw new Error(`Key not found: ${key}`);
        }
    }
}
