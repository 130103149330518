import { registry, container } from "tsyringe";
import { makeAutoObservable } from "mobx";
import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import sha384 from './sha';
import PaymentMethod from "./PaymentMethod";

@registry([{ token: SigninViewModel, useClass: SigninViewModel }])
export default class SigninViewModel {
    private get loginCommand(): cod.ICommand<cod.LoginCommandParameter> {
        return container.resolve<cod.ICommander>(
            nameof<cod.Services>((t) => t.ICommander)
        ).get(cod.Commands.login);
    }

    signinFailed: boolean = false;
    networkFailed: boolean = false;
    busy: boolean = false;
    username: string = "";
    password: string = "";

    private get authenticator(): cod.IAuthenticator {
        return container.resolve<cod.IAuthenticator>(
            nameof<cod.Services>((t) => t.IAuthenticator)
        );
    }

    constructor() {
        makeAutoObservable(this);
    }

    get paymentMethods(): PaymentMethod[] {
        if (!this.authenticator.isAuthenticated) {
            return [];
        }

        if (!this.authenticator.claims) {
            return [];
        }

        const claim = this.authenticator.claims["paymentMethods"];
        if (!claim) {
            return [];
        }

        return JSON.parse(claim) as PaymentMethod[];
    }

    onSignin = async (e: any): Promise<void> => {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target as HTMLFormElement;
        const validate = target.checkValidity();
        target.classList.add('was-validated');
        if (validate === false) {            
            return;
        }

        this.networkFailed = false;
        this.signinFailed = false;
        this.busy = true;
        try {
            const returnUrl = new URLSearchParams(window.location.search).get("returnUrl");
            const result = await this.loginCommand.execute(new cod.LoginCommandParameter({
                scheme: "Basic",
                username: this.username,
                password: await sha384(this.password),
                remember: true,
                returnUrl: returnUrl ?? "/"
            }));

            if (!result.result || !result.result.success) {
                if (result.result && result.result.code == cod.InternalError.networkFailure) {
                    this.networkFailed = true;
                } else {
                    this.signinFailed = true;
                }
            }

            if (this.authenticator.isAuthenticated && this.paymentMethods.length == 0) {
                let signupUrl = "/signup";
                const search = new URLSearchParams(window.location.search);
                const device = search.get("device");
                let promo = search.get("promo");

                if (device) {
                    signupUrl += '?device=' + device;
                    if (promo) {
                        signupUrl += '&promo=' + promo;
                    }
                }

                window.location.href = signupUrl;
            }
        } finally {
            this.busy = false;
        }        
    };

    onInput = (e: HTMLInputElement) => {
        (this as any)[e.name] = e.value.trim();
    };
}