import { nameof } from "ts-simple-nameof";
import { Reflection as Reflect } from "@abraham/reflection";
import * as cod from "@cod/cod-web";
import DeviceDomain from "./DeviceDomain";
import Device from "./Device";
import {
    registry,
    container,
    instanceCachingFactory,
    singleton,
} from "tsyringe";

@registry([
    {
        token: DeviceRepository,
        useFactory: instanceCachingFactory<DeviceRepository>((c) =>
            c.resolve(DeviceRepository)
        ),
    },
])
@singleton()
export default class DeviceRepository
    extends cod.GenericRepository<DeviceDomain, Device>
    implements cod.IRepository<DeviceDomain, Device> {
    constructor() {
        super();
        Reflect.defineMetadata(
            cod.Settings.METADATA_RESOURCE,
            "Device",
            this
        );
    }

    protected createDomain(): DeviceDomain {
        return container.resolve<DeviceDomain>(DeviceDomain);
    }
}
