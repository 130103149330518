import { FunctionalComponent, h } from "preact";
import { Route, Router, RouterOnChangeArgs } from "preact-router";

import Home from "../routes/home";
import Signin from "../routes/signin";
import Signup from "../routes/signup";
import OrderListing from "../routes/orders";
import NotFoundPage from "../routes/notfound";
import Rental from "../routes/rental";

import { container } from "tsyringe";

import "./registrar";
import HomeViewModel from './HomeViewModel';
import SignupViewModel from './SignupViewModel';
import SigninViewModel from './SigninViewModel';
import OrderListingViewModel from './OrderListingViewModel';
import RentalViewModel from './RentalViewModel';
import { TranslationsProvider } from '@eo-locale/preact';
import { locales, siteLanguage } from '../locales';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const homeViewModel = container.resolve<HomeViewModel>(HomeViewModel);
const signinViewModel = container.resolve<SigninViewModel>(SigninViewModel);
const signupViewModel = container.resolve<SignupViewModel>(SignupViewModel);
const orderListingViewModel = container.resolve<OrderListingViewModel>(OrderListingViewModel);
const rentalViewModel = container.resolve<RentalViewModel>(RentalViewModel);

const stripePromise = loadStripe('pk_live_51IJWc3BNwBqHnTcqn1QakwGWcUmqi9JmT7o3Qby4GpJWs4auJZk5LvQ7LbpOkUjkvlMGN32CBJz3k59ZXf8PDGlG00PhmejjfB');

const App: FunctionalComponent = () => {
    const handleRoute = (e: RouterOnChangeArgs) => {
        if (e.url.toUpperCase().indexOf("/BJF0") >= 0) {
            window.location.href = '/?device=' + e.url.toUpperCase().substr(1);
            return;
        }
    };

    return (
        <div id="app">
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <TranslationsProvider language={siteLanguage} locales={locales}>
                            <Router onChange={handleRoute}>
                                <Route path="/" component={Home} homeViewModel={homeViewModel} />
                                <Route path="/signin" component={Signin} signinViewModel={signinViewModel} />

                                <Route path="/signup" component={Signup} signupViewModel={signupViewModel} elements={elements} stripe={stripe} />
                                <Route path="/orders" component={OrderListing} orderListingViewModel={orderListingViewModel} />
                                <Route path="/rental" component={Rental} rentalViewModel={rentalViewModel} />
                                <NotFoundPage default />
                            </Router>
                        </TranslationsProvider>
                    )}
                </ElementsConsumer>
            </Elements>
        </div>
    );
};

setInterval(() => {
    rentalViewModel.update();
}, 1000);

export default App;
