import { registry, container } from "tsyringe";
import { makeAutoObservable } from "mobx";
import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import Commands from './Commands';
import SignupCommandParameter from './SignupCommandParameter';
import CheckAvailabilityCommandParameter from './CheckAvailabilityCommandParameter';
import { siteLanguage } from '../locales';
import sha384 from './sha';
import UpdatePaymentInfoCommandParameter from './UpdatePaymentInfoCommandParameter';

@registry([{ token: SignupViewModel, useClass: SignupViewModel }])
export default class SignupViewModel {
    private get signupCommand(): cod.ICommand<SignupCommandParameter> {
        return container.resolve<cod.ICommander>(
            nameof<cod.Services>((t) => t.ICommander)
        ).get(Commands.signup);
    }
    private get updatePaymentInfoCommand(): cod.ICommand<UpdatePaymentInfoCommandParameter> {
        return container.resolve<cod.ICommander>(
            nameof<cod.Services>((t) => t.ICommander)
        ).get(Commands.updatePaymentInfo);
    }
    private get checkAvailabilityCommand(): cod.ICommand<CheckAvailabilityCommandParameter> {
        return container.resolve<cod.ICommander>(
            nameof<cod.Services>((t) => t.ICommander)
        ).get(Commands.checkAvailability);
    }

    checkingAvailability: boolean = false;
    usernameAvailable: boolean | undefined;
    errmsg: string = "";

    signupFailed: boolean = false;
    busy: boolean = false;
    username: string = "";
    password: string = "";
    subscribe: boolean = true;
    name: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    onSignup = async (e: any, card: any, stripe: any): Promise<void> => {
        const search = new URLSearchParams(window.location.search);
        const device = search.get("device");
        if (!device) {
            this.errmsg = siteLanguage == 'en' ? "An error has occurred during registration, please close the current page and try again." : "注册时发生错误，请关闭当前页面并重试";
            return;
        }

        const target = e.target as HTMLFormElement;
        const nameInput = target["name"] as unknown as HTMLInputElement;
        nameInput.setCustomValidity("");

        let validate = target.checkValidity();
        target.classList.add('was-validated');

        this.name = this.name.trim();
        if (!this.name || this.name.length <= 0) {
            nameInput.setCustomValidity(siteLanguage == 'en' ? "Please provide cardholder's full name." : "请提供持卡人全名");
            validate = false;
        }

        if (validate === false) {
            return;
        }

        this.errmsg = "";
        this.busy = true;
        try {
            const pwd = await sha384(this.password);
            const result = await this.signupCommand.execute(new SignupCommandParameter({
                username: this.username,
                password: pwd,
                device: device,
            }));

            this.signupFailed = !result.result || !result.result.success;
            if (this.signupFailed) {
                if (result.result && result.result.code == 409) {
                    this.checkingAvailability = false;
                    this.usernameAvailable = false;
                }
                validate = false;
            }

            if (validate === false) {
                return;
            }

            const secret = result.result?.result as string;
            const registration = await stripe.confirmCardSetup(secret, {
                payment_method: {
                    card: card,
                    billing_details: {
                        name: this.name.trim(),
                        email: this.username.trim().toLowerCase(),
                    }
                }
            });

            if (!registration.setupIntent || registration.setupIntent.status != "succeeded") {
                this.errmsg = siteLanguage == 'en' ? "Failed to validate payment info: " + registration.error?.message : "验证付款信息时出错: " + registration.error?.message;
            } else {
                const paymentResult = await this.updatePaymentInfoCommand.execute(new UpdatePaymentInfoCommandParameter({
                    paymentInfo: registration.setupIntent.id,
                    secret: registration.setupIntent.client_secret,
                }));
                if (!paymentResult.result || !paymentResult.result.success) {
                    this.errmsg = siteLanguage == 'en' ? "Failed to validate payment info, please check your payment info and try again." : "验证付款信息时出错，请核验您的付款信息并重试";
                } else {
                    let returnUrl = `/?device=${device}`;
                    const promo = search.get("promo");
                    if (promo) {
                        returnUrl += `&promo=${promo}`;
                    }
                    window.location.href = returnUrl + "&rentnow=1";
                }                
            }
        } finally {
            this.busy = false;
        }
    };

    onInput = (e: HTMLInputElement) => {
        let v = e.value.trim();
        if (e.name == "expiration" && v.length >= 2) {
            v = v.replace(/[^0-9]/g, '');
            v = v.substr(0, 2) + "/" + v.substr(2, v.length - 2);
        }

        (this as any)[e.name] = v;
    };

    onBlur = (e: HTMLInputElement) => {
        this.checkingAvailability = false;
        this.usernameAvailable = undefined;

        if (e.name == "username") {
            const v = e.value.trim();
            if (v && v.length > 0 && v.indexOf('@') >= 0) {
                this.checkingAvailability = true;
                this.checkAvailability(v).then(r => {
                    this.usernameAvailable = r.success && r.result;
                }).finally(() => {
                    this.checkingAvailability = false;
                });
            }
        }
    };

    private async checkAvailability(username: string): Promise<cod.OperationResult<boolean>> {
        const result = await this.checkAvailabilityCommand.execute(new CheckAvailabilityCommandParameter(username));
        if (!result.result || !result.result.success || !result.result.result) {
            return new cod.OperationResult<boolean>({ result: false });
        }

        return new cod.OperationResult<boolean>({ result: result.result.result as boolean });
    }
}