export default class RentCommandParameter {
    device: string;
    paymentMethod: string;
    promoCode: string;
    constructor(param: {
        device: string,
        paymentMethod: string,
        promoCode: string
    }) {
        this.device = param.device.trim().toUpperCase();
        this.paymentMethod = param.paymentMethod;
        this.promoCode = param.promoCode.trim().toUpperCase();
    }
}