export default class SignupCommandParameter {
    username: string;
    password: string;
    device: string;

    constructor(param: {
        username: string;
        password: string;
        device: string;
    }) {
        this.username = param.username.trim();
        this.password = param.password.trim();
        this.device = param.device.trim().toUpperCase();
    }
}