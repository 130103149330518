export default class UpdatePaymentInfoCommandParameter {
    paymentInfo: string;
    secret: string;

    constructor(param: {
        paymentInfo: string;
        secret: string;
    }) {
        this.paymentInfo = param.paymentInfo.trim();
        this.secret = param.secret.trim();
    }
}