import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import { container } from "tsyringe";
import RentCommandParameter from './RentCommandParameter';
import Commands from './Commands';
import BusyGroups from './BusyGroups';
import Order from './Order';

export default class RentCommand extends cod.GenericCommand<RentCommandParameter, Order> {
    private get configurationProvider(): cod.IConfigurationProvider {
        return container.resolve<cod.IConfigurationProvider>(
            nameof<cod.Services>((t) => t.IConfigurationProvider)
        );
    }
    private get authenticator(): cod.IAuthenticator {
        return container.resolve<cod.IAuthenticator>(
            nameof<cod.Services>((t) => t.IAuthenticator)
        );
    }
    private get httpClient(): cod.IHttpClient {
        return container.resolve<cod.IHttpClient>(
            nameof<cod.Services>((t) => t.IHttpClient)
        );
    }

    public get id(): string {
        return Commands.rent;
    }

    protected async executeCore(parameter: RentCommandParameter): Promise<cod.OperationResult<Order>> {
        if (!this.authenticator.token) {
            const search = new URLSearchParams(window.location.search);
            let device = search.get("device");
            if (!device) {
                device = "";
            }
            let promo = search.get("promo");
            if (!promo) {
                promo = "";
            }
            const signinLink = '/signin?returnUrl=%2F%3Fdevice%3D' + device + '%26promo%3D' + promo;
            window.location.href = signinLink;
            return new cod.OperationResult({ code: cod.InternalError.authenticationRequired });
        }

        this.commander.setBusy(BusyGroups.rent, undefined);
        try {
            const apiuri = this.configurationProvider.get(cod.Settings.API_URI);
            const result = await this.httpClient.request<Order>({
                method: "POST",
                uri: `${apiuri}/v1/orders`,
                token: this.authenticator.token.value,
                body: {
                    device: parameter.device,
                    order: new Date("2050-01-01T00:00:00Z").getTime() - new Date().getTime(),
                    paymentMethod: parameter.paymentMethod,
                    promoCode: parameter.promoCode,
                }
            });

            if (!result.success) {
                return cod.OperationResult.parse<Order>(result);
            }

            const order = result.result?.data;
            return new cod.OperationResult<Order>({ result: order });

        } finally {
            this.commander.setBusy(BusyGroups.rent, undefined);
        }
    }
}