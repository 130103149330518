import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import { container } from "tsyringe";
import SignupCommandParameter from './SignupCommandParameter';
import Commands from './Commands';
import BusyGroups from './BusyGroups';

export default class SignupCommand extends cod.GenericCommand<SignupCommandParameter, string> {
    private get configurationProvider(): cod.IConfigurationProvider {
        return container.resolve<cod.IConfigurationProvider>(
            nameof<cod.Services>((t) => t.IConfigurationProvider)
        );
    }
    private get httpClient(): cod.IHttpClient {
        return container.resolve<cod.IHttpClient>(
            nameof<cod.Services>((t) => t.IHttpClient)
        );
    }

    public get id(): string {
        return Commands.signup;
    }

    protected async executeCore(parameter: SignupCommandParameter): Promise<cod.OperationResult<string>> {
        this.commander.setBusy(BusyGroups.signup, undefined);
        try {
            const apiuri = this.configurationProvider.get(cod.Settings.API_URI);
            const result = await this.httpClient.request<string>({
                method: "POST",
                uri: `${apiuri}/v2/users`,
                body: {
                    email: parameter.username,
                    device: parameter.device,
                    password: parameter.password,
                    provider: 3
                }
            });

            if (!result.success) {
                return cod.OperationResult.parse<string>(result);
            }

            return new cod.OperationResult<string>({ result: result.result?.data });

        } finally {
            this.commander.setBusy(BusyGroups.signup, undefined);
        }
    }
}