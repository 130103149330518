import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import { container } from "tsyringe";
import Commands from './Commands';
import BusyGroups from './BusyGroups';
import UpdatePaymentInfoCommandParameter from './UpdatePaymentInfoCommandParameter';

export default class UpdatePaymentInfoCommand extends cod.GenericCommand<UpdatePaymentInfoCommandParameter, void> {
    private get configurationProvider(): cod.IConfigurationProvider {
        return container.resolve<cod.IConfigurationProvider>(
            nameof<cod.Services>((t) => t.IConfigurationProvider)
        );
    }
    private get httpClient(): cod.IHttpClient {
        return container.resolve<cod.IHttpClient>(
            nameof<cod.Services>((t) => t.IHttpClient)
        );
    }
    private get authenticator(): cod.IAuthenticator {
        return container.resolve<cod.IAuthenticator>(
            nameof<cod.Services>((t) => t.IAuthenticator)
        );
    }

    public get id(): string {
        return Commands.updatePaymentInfo;
    }

    protected async executeCore(parameter: UpdatePaymentInfoCommandParameter): Promise<cod.OperationResult<void>> {
        if (!this.authenticator.token) {
            const search = new URLSearchParams(window.location.search);
            let device = search.get("device");
            if (!device) {
                device = "";
            }
            let promo = search.get("promo");
            if (!promo) {
                promo = "";
            }
            const signinLink = '/signin?returnUrl=%2F%3Fdevice%3D' + device + '%26promo%3D' + promo;
            window.location.href = signinLink;
            return new cod.OperationResult({ code: cod.InternalError.authenticationRequired });
        }

        this.commander.setBusy(BusyGroups.signup, undefined);
        try {
            const apiuri = this.configurationProvider.get(cod.Settings.API_URI);
            const result = await this.httpClient.request<string>({
                method: "POST",
                uri: `${apiuri}/v2/users/payment`,
                token: this.authenticator.token.value,
                body: {
                    paymentInfo: parameter.paymentInfo,
                    secret: parameter.secret,
                    provider: 3
                }
            });

            if (!result.success) {
                return cod.OperationResult.parse<void>(result);
            }

            return new cod.OperationResult<void>({ code: cod.OperationResult.successCode });
        } finally {
            this.commander.setBusy(BusyGroups.signup, undefined);
        }
    }
}