import { IEntity } from "@cod/cod-web";

export default class Device implements IEntity {
    PartitionKey!: string;
    RowKey!: string;
    ETag!: string;

    Address1!: string;
    Address2: string | undefined | null;
    Suburb!: string;
    City!: string;
    Country!: string;
    Zipcode!: string;
    DisplayName!: string;
    ChineseDisplayName!: string;

    GoogleMapLink: string | null | undefined;

    public static buildPartitionKey(device: string): string {
        return device.toUpperCase().substring(0, 3);
    }

    static buildRowKey(device: string): string {
        return device.toUpperCase();
    }
}