import Order from "./Order";
import { IChannelDomain, ChannelDomain } from "@cod/cod-web";
import { registry, container } from "tsyringe";
import DeviceRepository from '../routes/device/DeviceRepository';
import { siteLanguage } from '../locales';
import Device from '../routes/device/Device';

@registry([{ token: OrderDomain, useClass: OrderDomain }])
export default class OrderDomain
    extends ChannelDomain<Order>
    implements IChannelDomain<Order> {
    private get deviceRepository(): DeviceRepository {
        return container.resolve<DeviceRepository>(DeviceRepository);
    }
    private static readonly DefaultMaxCost: number = 1998;
    private static readonly DefaultDayMaxCost: number = 698;
    private static readonly DefaultFirstHourCost: number = 199;
    private static readonly DefaultPrice: number = 99;
    private static readonly DefaultMaxCostHours: number = 24;
    private static readonly Hour = 60 * 60 * 1000;

    get sourceLocationGoogleMapLink(): string | undefined {
        const order = this.entity;
        const sourceID = order.Source.split("$$$")[0];
        const devices = this.deviceRepository.data;
        const sourceDeivce = devices.singleOrDefault(d => d.entity.RowKey == sourceID);
        if (sourceDeivce) {
            return sourceDeivce.entity.GoogleMapLink ?? undefined;
        }

        return undefined;
    }

    get settledCost(): string {
        const tbd = siteLanguage == "en" ? "TBD" : "待定";
        const status = this.entity.Status;
        if (status == 0) {
            return tbd;
        } else if (status == 1) {
            return tbd;
        } else if (status == 2) {
            return tbd;
        } else if (status == 3) {
            return '$' + `${(this.entity.Paid / 100).toFixed(2)}`;
        } else if (status == 4) {
            return "$0.00";
        }

        return tbd;
    }

    get sourceLocationName(): string {
        const order = this.entity;
        const sourceID = order.Source.split("$$$")[0];
        const devices = this.deviceRepository.data;
        const sourceDeivce = devices.singleOrDefault(d => d.entity.RowKey == sourceID);
        if (sourceDeivce) {
            const val = siteLanguage == "en" ? sourceDeivce.entity.DisplayName : sourceDeivce.entity.ChineseDisplayName;
            return val ?? sourceDeivce.entity.DisplayName;
        }

        return "";
    }

    get returnLocationName(): string {
        const order = this.entity;
        if (order.ReturnedTo) {
            const sourceID = order.ReturnedTo.split("$$$")[0];
            const devices = this.deviceRepository.data;
            const sourceDeivce = devices.singleOrDefault(d => d.entity.RowKey == sourceID);
            if (sourceDeivce) {
                const val = siteLanguage == "en" ? sourceDeivce.entity.DisplayName : sourceDeivce.entity.ChineseDisplayName;
                return val ?? sourceDeivce.entity.DisplayName;
            }
        }

        return "";
    }

    get returnLocationGoogleMapLink(): string | undefined {
        const order = this.entity;
        if (order.ReturnedTo) {
            const sourceID = order.ReturnedTo.split("$$$")[0];
            const devices = this.deviceRepository.data;
            const sourceDeivce = devices.singleOrDefault(d => d.entity.RowKey == sourceID);
            if (sourceDeivce) {
                return sourceDeivce.entity.GoogleMapLink ?? undefined;
            }
        }

        return undefined;
    }

    get CurrentCost(): number {
        const returned = new Date();
        const returnedTS = returned.getTime();
        const order = this.entity;
        if (order.Promo && order.Promo.toUpperCase() === "PLUGO") {
            var promoReturned = new Date(returnedTS - OrderDomain.Hour);
            const promoDebt = this.Figure(this.entity.Shipped, promoReturned.toUTCString());
            return promoDebt;
        }
        else {
            return this.Figure(this.entity.Shipped, returned.toUTCString());
        }
    }

    get IsBalanceDue(): boolean {
        let result = false;
        const order = this.entity;
        if (order.Status == 3) {
            const paid = order.Paid / 100;
            if (order.Returned != undefined && order.Returned != null) {
                const start = new Date(order.Shipped);
                const end = new Date(order.Returned)

                const balance = this.Figure(start.toUTCString(), end.toUTCString());
                result = paid - balance < 0;
            }
        }
        return result;
    }

    public async loadDevice(): Promise<void> {
        const order = this.entity;
        if (order.Source) {
            const deviceID = order.Source.split("$$$")[0];
            let source = this.deviceRepository.data.singleOrDefault(d => d.entity.RowKey == deviceID);
            if (!source) {
                await this.deviceRepository.loadSingle({
                    partitionKey: Device.buildPartitionKey(deviceID),
                    rowKey: Device.buildRowKey(deviceID),
                })
            }
        }
        if (order.ReturnedTo) {
            const deviceID = order.ReturnedTo.split("$$$")[0];
            let source = this.deviceRepository.data.singleOrDefault(d => d.entity.RowKey == deviceID);
            if (!source) {
                await this.deviceRepository.loadSingle({
                    partitionKey: Device.buildPartitionKey(deviceID),
                    rowKey: Device.buildRowKey(deviceID),
                })
            }
        }
    }

    private Figure(rended: string, returned: string): number {
        const rentTS = new Date(rended);
        const returnTS = new Date(returned);

        if (((returnTS.getTime() - rentTS.getTime())) / 1000 / 60 <= 5) {
            return 0;
        }

        const totalHours = Math.ceil((returnTS.getTime() - rentTS.getTime()) / 1000 / 60 / 60);

        let amount = 0;
        if (totalHours <= 24) {
            amount = OrderDomain.CalculateFirstDay(totalHours);
        }
        else {
            var firstDayAmount = OrderDomain.CalculateFirstDay(24);
            var otherDayAmount = OrderDomain.Calculate(totalHours - 24);
            amount = firstDayAmount + otherDayAmount;
        }

        const ret = amount >= OrderDomain.DefaultMaxCost ? OrderDomain.DefaultMaxCost : amount;
        return ret / 100;
    }

    private static CalculateFirstDay(hours: number): number {
        var amount = 0;
        if (hours >= 1) {
            amount += OrderDomain.DefaultFirstHourCost;
            hours -= 1;
        }

        if (hours <= 0) {
            return amount;
        }

        const estimate = hours * OrderDomain.DefaultPrice;
        return OrderDomain.CheckDayCap(amount + estimate);
    }

    private static Calculate(hours: number) {
        const days = Math.trunc(hours / 24);
        const remainHours = hours % 24;
        const amount = days * OrderDomain.DefaultDayMaxCost;
        const estimate = OrderDomain.CheckDayCap(remainHours * OrderDomain.DefaultPrice);
        return amount + estimate;
    }

    private static CheckDayCap(amount: number) {
        return amount > OrderDomain.DefaultDayMaxCost ? OrderDomain.DefaultDayMaxCost : amount;
    }
}