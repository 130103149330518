export default class LoadOrderCommandParameter {
    partitionKey: string;
    rowKey: string;

    constructor(param: {
        partitionKey: string,
        rowKey: string
    }) {
        this.partitionKey = param.partitionKey;
        this.rowKey = param.rowKey;
    }
}