import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import { container } from "tsyringe";
import Commands from './Commands';
import BusyGroups from './BusyGroups';
import CheckAvailabilityCommandParameter from './CheckAvailabilityCommandParameter';

export default class CheckAvailabilityCommand extends cod.GenericCommand<CheckAvailabilityCommandParameter, boolean> {
    private get configurationProvider(): cod.IConfigurationProvider {
        return container.resolve<cod.IConfigurationProvider>(
            nameof<cod.Services>((t) => t.IConfigurationProvider)
        );
    }
    private get httpClient(): cod.IHttpClient {
        return container.resolve<cod.IHttpClient>(
            nameof<cod.Services>((t) => t.IHttpClient)
        );
    }

    public get id(): string {
        return Commands.checkAvailability;
    }

    protected async executeCore(parameter: CheckAvailabilityCommandParameter): Promise<cod.OperationResult<boolean>> {
        this.commander.setBusy(BusyGroups.checkAvailability, undefined);
        try {
            const apiuri = this.configurationProvider.get(cod.Settings.API_URI);
            const result = await this.httpClient.request<void>({
                method: "HEAD",
                uri: `${apiuri}/v2/users/${encodeURI(parameter.username)}`,
            });

            if (!result.success) {
                return cod.OperationResult.parse<boolean>(result);
            }

            return new cod.OperationResult<boolean>({ result: true });

        } finally {
            this.commander.setBusy(BusyGroups.checkAvailability, undefined);
        }
    }
}