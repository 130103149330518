import { Reflection as Reflect } from "@abraham/reflection";
import * as cod from "@cod/cod-web";
import OrderDomain from "./OrderDomain";
import Order from "./Order";
import {
    registry,
    container,
    instanceCachingFactory,
    singleton,
} from "tsyringe";

@registry([
    {
        token: OrderRepository,
        useFactory: instanceCachingFactory<OrderRepository>((c) =>
            c.resolve(OrderRepository)
        ),
    },
])
@singleton()
export default class OrderRepository
    extends cod.GenericRepository<OrderDomain, Order>
    implements cod.IRepository<OrderDomain, Order> {
    constructor() {
        super();
        Reflect.defineMetadata(
            cod.Settings.METADATA_RESOURCE,
            "Order",
            this
        );
    }

    protected createDomain(): OrderDomain {
        return container.resolve<OrderDomain>(OrderDomain);
    }
}
