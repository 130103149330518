import { container } from "tsyringe";
import * as cod from "@cod/cod-web";
import { nameof } from "ts-simple-nameof";
import "./RuntimeConfigurationProvider";
import SignupCommand from "./SignupCommand";
import CheckAvailabilityCommand from './CheckAvailabilityCommand';
import RentCommand from './RentCommand';
import LoadOrderListCommand from './LoadOrderListCommand';
import LoadOrderCommand from './LoadOrderCommand';
import UpdatePaymentInfoCommand from './UpdatePaymentInfoCommand';

container.register(nameof<cod.Services>(t => t.ICommand), SignupCommand);
container.register(nameof<cod.Services>(t => t.ICommand), UpdatePaymentInfoCommand);
container.register(nameof<cod.Services>(t => t.ICommand), CheckAvailabilityCommand);
container.register(nameof<cod.Services>(t => t.ICommand), RentCommand);
container.register(nameof<cod.Services>(t => t.ICommand), LoadOrderListCommand);
container.register(nameof<cod.Services>(t => t.ICommand), LoadOrderCommand);